@font-face {
  font-family: Flaticon;
  src: url(Flaticon.eot);
  src: url(Flaticon.eot?#iefix) format("embedded-opentype"),
    url(Flaticon.woff) format("woff"), url(Flaticon.ttf) format("truetype"),
    url(Flaticon.svg#Flaticon) format("svg");
  font-weight: 400;
  font-style: normal;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: Flaticon;
    src: url(Flaticon.svg#Flaticon) format("svg");
  }
}
[class*=" flaticon-"]:after,
[class*=" flaticon-"]:before,
[class^="flaticon-"]:after,
[class^="flaticon-"]:before {
  font-family: Flaticon;
  font-style: normal;
}
.flaticon-381-add:before {
  content: "\f100";
}
.flaticon-381-add-1:before {
  content: "\f101";
}
.flaticon-381-add-2:before {
  content: "\f102";
}
.flaticon-381-add-3:before {
  content: "\f103";
}
.flaticon-381-alarm-clock:before {
  content: "\f104";
}
.flaticon-381-alarm-clock-1:before {
  content: "\f105";
}
.flaticon-381-album:before {
  content: "\f106";
}
.flaticon-381-album-1:before {
  content: "\f107";
}
.flaticon-381-album-2:before {
  content: "\f108";
}
.flaticon-381-album-3:before {
  content: "\f109";
}
.flaticon-381-app:before {
  content: "\f10a";
}
.flaticon-381-archive:before {
  content: "\f10b";
}
.flaticon-381-back:before {
  content: "\f10c";
}
.flaticon-381-back-1:before {
  content: "\f10d";
}
.flaticon-381-back-2:before {
  content: "\f10e";
}
.flaticon-381-background:before {
  content: "\f10f";
}
.flaticon-381-background-1:before {
  content: "\f110";
}
.flaticon-381-battery:before {
  content: "\f111";
}
.flaticon-381-battery-1:before {
  content: "\f112";
}
.flaticon-381-battery-2:before {
  content: "\f113";
}
.flaticon-381-battery-3:before {
  content: "\f114";
}
.flaticon-381-battery-4:before {
  content: "\f115";
}
.flaticon-381-battery-5:before {
  content: "\f116";
}
.flaticon-381-battery-6:before {
  content: "\f117";
}
.flaticon-381-battery-7:before {
  content: "\f118";
}
.flaticon-381-battery-8:before {
  content: "\f119";
}
.flaticon-381-battery-9:before {
  content: "\f11a";
}
.flaticon-381-binoculars:before {
  content: "\f11b";
}
.flaticon-381-blueprint:before {
  content: "\f11c";
}
.flaticon-381-bluetooth:before {
  content: "\f11d";
}
.flaticon-381-bluetooth-1:before {
  content: "\f11e";
}
.flaticon-381-book:before {
  content: "\f11f";
}
.flaticon-381-bookmark:before {
  content: "\f120";
}
.flaticon-381-bookmark-1:before {
  content: "\f121";
}
.flaticon-381-box:before {
  content: "\f122";
}
.flaticon-381-box-1:before {
  content: "\f123";
}
.flaticon-381-box-2:before {
  content: "\f124";
}
.flaticon-381-briefcase:before {
  content: "\f125";
}
.flaticon-381-broken-heart:before {
  content: "\f126";
}
.flaticon-381-broken-link:before {
  content: "\f127";
}
.flaticon-381-calculator:before {
  content: "\f128";
}
.flaticon-381-calculator-1:before {
  content: "\f129";
}
.flaticon-381-calendar:before {
  content: "\f12a";
}
.flaticon-381-calendar-1:before {
  content: "\f12b";
}
.flaticon-381-calendar-2:before {
  content: "\f12c";
}
.flaticon-381-calendar-3:before {
  content: "\f12d";
}
.flaticon-381-calendar-4:before {
  content: "\f12e";
}
.flaticon-381-calendar-5:before {
  content: "\f12f";
}
.flaticon-381-calendar-6:before {
  content: "\f130";
}
.flaticon-381-calendar-7:before {
  content: "\f131";
}
.flaticon-381-clock:before {
  content: "\f132";
}
.flaticon-381-clock-1:before {
  content: "\f133";
}
.flaticon-381-clock-2:before {
  content: "\f134";
}
.flaticon-381-close:before {
  content: "\f135";
}
.flaticon-381-cloud:before {
  content: "\f136";
}
.flaticon-381-cloud-computing:before {
  content: "\f137";
}
.flaticon-381-command:before {
  content: "\f138";
}
.flaticon-381-compact-disc:before {
  content: "\f139";
}
.flaticon-381-compact-disc-1:before {
  content: "\f13a";
}
.flaticon-381-compact-disc-2:before {
  content: "\f13b";
}
.flaticon-381-compass:before {
  content: "\f13c";
}
.flaticon-381-compass-1:before {
  content: "\f13d";
}
.flaticon-381-compass-2:before {
  content: "\f13e";
}
.flaticon-381-controls:before {
  content: "\f13f";
}
.flaticon-381-controls-1:before {
  content: "\f140";
}
.flaticon-381-controls-2:before {
  content: "\f141";
}
.flaticon-381-controls-3:before {
  content: "\f142";
}
.flaticon-381-controls-4:before {
  content: "\f143";
}
.flaticon-381-controls-5:before {
  content: "\f144";
}
.flaticon-381-controls-6:before {
  content: "\f145";
}
.flaticon-381-controls-7:before {
  content: "\f146";
}
.flaticon-381-controls-8:before {
  content: "\f147";
}
.flaticon-381-controls-9:before {
  content: "\f148";
}
.flaticon-381-database:before {
  content: "\f149";
}
.flaticon-381-database-1:before {
  content: "\f14a";
}
.flaticon-381-database-2:before {
  content: "\f14b";
}
.flaticon-381-diamond:before {
  content: "\f14c";
}
.flaticon-381-diploma:before {
  content: "\f14d";
}
.flaticon-381-dislike:before {
  content: "\f14e";
}
.flaticon-381-divide:before {
  content: "\f14f";
}
.flaticon-381-division:before {
  content: "\f150";
}
.flaticon-381-division-1:before {
  content: "\f151";
}
.flaticon-381-download:before {
  content: "\f152";
}
.flaticon-381-earth-globe:before {
  content: "\f153";
}
.flaticon-381-earth-globe-1:before {
  content: "\f154";
}
.flaticon-381-edit:before {
  content: "\f155";
}
.flaticon-381-edit-1:before {
  content: "\f156";
}
.flaticon-381-eject:before {
  content: "\f157";
}
.flaticon-381-eject-1:before {
  content: "\f158";
}
.flaticon-381-enter:before {
  content: "\f159";
}
.flaticon-381-equal:before {
  content: "\f15a";
}
.flaticon-381-equal-1:before {
  content: "\f15b";
}
.flaticon-381-equal-2:before {
  content: "\f15c";
}
.flaticon-381-error:before {
  content: "\f15d";
}
.flaticon-381-exit:before {
  content: "\f15e";
}
.flaticon-381-exit-1:before {
  content: "\f15f";
}
.flaticon-381-exit-2:before {
  content: "\f160";
}
.flaticon-381-fast-forward:before {
  content: "\f161";
}
.flaticon-381-fast-forward-1:before {
  content: "\f162";
}
.flaticon-381-file:before {
  content: "\f163";
}
.flaticon-381-file-1:before {
  content: "\f164";
}
.flaticon-381-file-2:before {
  content: "\f165";
}
.flaticon-381-film-strip:before {
  content: "\f166";
}
.flaticon-381-film-strip-1:before {
  content: "\f167";
}
.flaticon-381-fingerprint:before {
  content: "\f168";
}
.flaticon-381-flag:before {
  content: "\f169";
}
.flaticon-381-flag-1:before {
  content: "\f16a";
}
.flaticon-381-flag-2:before {
  content: "\f16b";
}
.flaticon-381-flag-3:before {
  content: "\f16c";
}
.flaticon-381-flag-4:before {
  content: "\f16d";
}
.flaticon-381-focus:before {
  content: "\f16e";
}
.flaticon-381-folder:before {
  content: "\f16f";
}
.flaticon-381-folder-1:before {
  content: "\f170";
}
.flaticon-381-folder-10:before {
  content: "\f171";
}
.flaticon-381-folder-11:before {
  content: "\f172";
}
.flaticon-381-folder-12:before {
  content: "\f173";
}
.flaticon-381-folder-13:before {
  content: "\f174";
}
.flaticon-381-folder-14:before {
  content: "\f175";
}
.flaticon-381-folder-15:before {
  content: "\f176";
}
.flaticon-381-folder-16:before {
  content: "\f177";
}
.flaticon-381-folder-17:before {
  content: "\f178";
}
.flaticon-381-folder-18:before {
  content: "\f179";
}
.flaticon-381-folder-19:before {
  content: "\f17a";
}
.flaticon-381-folder-2:before {
  content: "\f17b";
}
.flaticon-381-folder-3:before {
  content: "\f17c";
}
.flaticon-381-folder-4:before {
  content: "\f17d";
}
.flaticon-381-folder-5:before {
  content: "\f17e";
}
.flaticon-381-folder-6:before {
  content: "\f17f";
}
.flaticon-381-folder-7:before {
  content: "\f180";
}
.flaticon-381-folder-8:before {
  content: "\f181";
}
.flaticon-381-folder-9:before {
  content: "\f182";
}
.flaticon-381-forbidden:before {
  content: "\f183";
}
.flaticon-381-funnel:before {
  content: "\f184";
}
.flaticon-381-gift:before {
  content: "\f185";
}
.flaticon-381-heart:before {
  content: "\f186";
}
.flaticon-381-heart-1:before {
  content: "\f187";
}
.flaticon-381-help:before {
  content: "\f188";
}
.flaticon-381-help-1:before {
  content: "\f189";
}
.flaticon-381-hide:before {
  content: "\f18a";
}
.flaticon-381-high-volume:before {
  content: "\f18b";
}
.flaticon-381-home:before {
  content: "\f18c";
}
.flaticon-381-home-1:before {
  content: "\f18d";
}
.flaticon-381-home-2:before {
  content: "\f18e";
}
.flaticon-381-home-3:before {
  content: "\f18f";
}
.flaticon-381-hourglass:before {
  content: "\f190";
}
.flaticon-381-hourglass-1:before {
  content: "\f191";
}
.flaticon-381-hourglass-2:before {
  content: "\f192";
}
.flaticon-381-id-card:before {
  content: "\f193";
}
.flaticon-381-id-card-1:before {
  content: "\f194";
}
.flaticon-381-id-card-2:before {
  content: "\f195";
}
.flaticon-381-id-card-3:before {
  content: "\f196";
}
.flaticon-381-id-card-4:before {
  content: "\f197";
}
.flaticon-381-id-card-5:before {
  content: "\f198";
}
.flaticon-381-idea:before {
  content: "\f199";
}
.flaticon-381-incoming-call:before {
  content: "\f19a";
}
.flaticon-381-infinity:before {
  content: "\f19b";
}
.flaticon-381-internet:before {
  content: "\f19c";
}
.flaticon-381-key:before {
  content: "\f19d";
}
.flaticon-381-knob:before {
  content: "\f19e";
}
.flaticon-381-knob-1:before {
  content: "\f19f";
}
.flaticon-381-layer:before {
  content: "\f1a0";
}
.flaticon-381-layer-1:before {
  content: "\f1a1";
}
.flaticon-381-like:before {
  content: "\f1a2";
}
.flaticon-381-link:before {
  content: "\f1a3";
}
.flaticon-381-link-1:before {
  content: "\f1a4";
}
.flaticon-381-list:before {
  content: "\f1a5";
}
.flaticon-381-list-1:before {
  content: "\f1a6";
}
.flaticon-381-location:before {
  content: "\f1a7";
}
.flaticon-381-location-1:before {
  content: "\f1a8";
}
.flaticon-381-location-2:before {
  content: "\f1a9";
}
.flaticon-381-location-3:before {
  content: "\f1aa";
}
.flaticon-381-location-4:before {
  content: "\f1ab";
}
.flaticon-381-locations:before {
  content: "\f1ac";
}
.flaticon-381-lock:before {
  content: "\f1ad";
}
.flaticon-381-lock-1:before {
  content: "\f1ae";
}
.flaticon-381-lock-2:before {
  content: "\f1af";
}
.flaticon-381-lock-3:before {
  content: "\f1b0";
}
.flaticon-381-low-volume:before {
  content: "\f1b1";
}
.flaticon-381-low-volume-1:before {
  content: "\f1b2";
}
.flaticon-381-low-volume-2:before {
  content: "\f1b3";
}
.flaticon-381-low-volume-3:before {
  content: "\f1b4";
}
.flaticon-381-magic-wand:before {
  content: "\f1b5";
}
.flaticon-381-magnet:before {
  content: "\f1b6";
}
.flaticon-381-magnet-1:before {
  content: "\f1b7";
}
.flaticon-381-magnet-2:before {
  content: "\f1b8";
}
.flaticon-381-map:before {
  content: "\f1b9";
}
.flaticon-381-map-1:before {
  content: "\f1ba";
}
.flaticon-381-map-2:before {
  content: "\f1bb";
}
.flaticon-381-menu:before {
  content: "\f1bc";
}
.flaticon-381-menu-1:before {
  content: "\f1bd";
}
.flaticon-381-menu-2:before {
  content: "\f1be";
}
.flaticon-381-menu-3:before {
  content: "\f1bf";
}
.flaticon-381-microphone:before {
  content: "\f1c0";
}
.flaticon-381-microphone-1:before {
  content: "\f1c1";
}
.flaticon-381-more:before {
  content: "\f1c2";
}
.flaticon-381-more-1:before {
  content: "\f1c3";
}
.flaticon-381-more-2:before {
  content: "\f1c4";
}
.flaticon-381-multiply:before {
  content: "\f1c5";
}
.flaticon-381-multiply-1:before {
  content: "\f1c6";
}
.flaticon-381-music-album:before {
  content: "\f1c7";
}
.flaticon-381-mute:before {
  content: "\f1c8";
}
.flaticon-381-mute-1:before {
  content: "\f1c9";
}
.flaticon-381-mute-2:before {
  content: "\f1ca";
}
.flaticon-381-network:before {
  content: "\f1cb";
}
.flaticon-381-network-1:before {
  content: "\f1cc";
}
.flaticon-381-network-2:before {
  content: "\f1cd";
}
.flaticon-381-network-3:before {
  content: "\f1ce";
}
.flaticon-381-networking:before {
  content: "\f1cf";
}
.flaticon-381-networking-1:before {
  content: "\f1d0";
}
.flaticon-381-news:before {
  content: "\f1d1";
}
.flaticon-381-newspaper:before {
  content: "\f1d2";
}
.flaticon-381-next:before {
  content: "\f1d3";
}
.flaticon-381-next-1:before {
  content: "\f1d4";
}
.flaticon-381-note:before {
  content: "\f1d5";
}
.flaticon-381-notebook:before {
  content: "\f1d6";
}
.flaticon-381-notebook-1:before {
  content: "\f1d7";
}
.flaticon-381-notebook-2:before {
  content: "\f1d8";
}
.flaticon-381-notebook-3:before {
  content: "\f1d9";
}
.flaticon-381-notebook-4:before {
  content: "\f1da";
}
.flaticon-381-notebook-5:before {
  content: "\f1db";
}
.flaticon-381-notepad:before {
  content: "\f1dc";
}
.flaticon-381-notepad-1:before {
  content: "\f1dd";
}
.flaticon-381-notepad-2:before {
  content: "\f1de";
}
.flaticon-381-notification:before {
  content: "\f1df";
}
.flaticon-381-off:before {
  content: "\f1e0";
}
.flaticon-381-on:before {
  content: "\f1e1";
}
.flaticon-381-pad:before {
  content: "\f1e2";
}
.flaticon-381-padlock:before {
  content: "\f1e3";
}
.flaticon-381-padlock-1:before {
  content: "\f1e4";
}
.flaticon-381-padlock-2:before {
  content: "\f1e5";
}
.flaticon-381-panel:before {
  content: "\f1e6";
}
.flaticon-381-panel-1:before {
  content: "\f1e7";
}
.flaticon-381-panel-2:before {
  content: "\f1e8";
}
.flaticon-381-panel-3:before {
  content: "\f1e9";
}
.flaticon-381-paperclip:before {
  content: "\f1ea";
}
.flaticon-381-pause:before {
  content: "\f1eb";
}
.flaticon-381-pause-1:before {
  content: "\f1ec";
}
.flaticon-381-pencil:before {
  content: "\f1ed";
}
.flaticon-381-percentage:before {
  content: "\f1ee";
}
.flaticon-381-percentage-1:before {
  content: "\f1ef";
}
.flaticon-381-perspective:before {
  content: "\f1f0";
}
.flaticon-381-phone-call:before {
  content: "\f1f1";
}
.flaticon-381-photo:before {
  content: "\f1f2";
}
.flaticon-381-photo-camera:before {
  content: "\f1f3";
}
.flaticon-381-photo-camera-1:before {
  content: "\f1f4";
}
.flaticon-381-picture:before {
  content: "\f1f5";
}
.flaticon-381-picture-1:before {
  content: "\f1f6";
}
.flaticon-381-picture-2:before {
  content: "\f1f7";
}
.flaticon-381-pin:before {
  content: "\f1f8";
}
.flaticon-381-play-button:before {
  content: "\f1f9";
}
.flaticon-381-play-button-1:before {
  content: "\f1fa";
}
.flaticon-381-plus:before {
  content: "\f1fb";
}
.flaticon-381-presentation:before {
  content: "\f1fc";
}
.flaticon-381-price-tag:before {
  content: "\f1fd";
}
.flaticon-381-print:before {
  content: "\f1fe";
}
.flaticon-381-print-1:before {
  content: "\f1ff";
}
.flaticon-381-privacy:before {
  content: "\f200";
}
.flaticon-381-promotion:before {
  content: "\f201";
}
.flaticon-381-promotion-1:before {
  content: "\f202";
}
.flaticon-381-push-pin:before {
  content: "\f203";
}
.flaticon-381-quaver:before {
  content: "\f204";
}
.flaticon-381-quaver-1:before {
  content: "\f205";
}
.flaticon-381-radar:before {
  content: "\f206";
}
.flaticon-381-reading:before {
  content: "\f207";
}
.flaticon-381-receive:before {
  content: "\f208";
}
.flaticon-381-record:before {
  content: "\f209";
}
.flaticon-381-repeat:before {
  content: "\f20a";
}
.flaticon-381-repeat-1:before {
  content: "\f20b";
}
.flaticon-381-resume:before {
  content: "\f20c";
}
.flaticon-381-rewind:before {
  content: "\f20d";
}
.flaticon-381-rewind-1:before {
  content: "\f20e";
}
.flaticon-381-ring:before {
  content: "\f20f";
}
.flaticon-381-ring-1:before {
  content: "\f210";
}
.flaticon-381-rotate:before {
  content: "\f211";
}
.flaticon-381-rotate-1:before {
  content: "\f212";
}
.flaticon-381-route:before {
  content: "\f213";
}
.flaticon-381-save:before {
  content: "\f214";
}
.flaticon-381-search:before {
  content: "\f215";
}
.flaticon-381-search-1:before {
  content: "\f216";
}
.flaticon-381-search-2:before {
  content: "\f217";
}
.flaticon-381-search-3:before {
  content: "\f218";
}
.flaticon-381-send:before {
  content: "\f219";
}
.flaticon-381-send-1:before {
  content: "\f21a";
}
.flaticon-381-send-2:before {
  content: "\f21b";
}
.flaticon-381-settings:before {
  content: "\f21c";
}
.flaticon-381-settings-1:before {
  content: "\f21d";
}
.flaticon-381-settings-2:before {
  content: "\f21e";
}
.flaticon-381-settings-3:before {
  content: "\f21f";
}
.flaticon-381-settings-4:before {
  content: "\f220";
}
.flaticon-381-settings-5:before {
  content: "\f221";
}
.flaticon-381-settings-6:before {
  content: "\f222";
}
.flaticon-381-settings-7:before {
  content: "\f223";
}
.flaticon-381-settings-8:before {
  content: "\f224";
}
.flaticon-381-settings-9:before {
  content: "\f225";
}
.flaticon-381-share:before {
  content: "\f226";
}
.flaticon-381-share-1:before {
  content: "\f227";
}
.flaticon-381-share-2:before {
  content: "\f228";
}
.flaticon-381-shuffle:before {
  content: "\f229";
}
.flaticon-381-shuffle-1:before {
  content: "\f22a";
}
.flaticon-381-shut-down:before {
  content: "\f22b";
}
.flaticon-381-silence:before {
  content: "\f22c";
}
.flaticon-381-silent:before {
  content: "\f22d";
}
.flaticon-381-smartphone:before {
  content: "\f22e";
}
.flaticon-381-smartphone-1:before {
  content: "\f22f";
}
.flaticon-381-smartphone-2:before {
  content: "\f230";
}
.flaticon-381-smartphone-3:before {
  content: "\f231";
}
.flaticon-381-smartphone-4:before {
  content: "\f232";
}
.flaticon-381-smartphone-5:before {
  content: "\f233";
}
.flaticon-381-smartphone-6:before {
  content: "\f234";
}
.flaticon-381-smartphone-7:before {
  content: "\f235";
}
.flaticon-381-speaker:before {
  content: "\f236";
}
.flaticon-381-speedometer:before {
  content: "\f237";
}
.flaticon-381-spotlight:before {
  content: "\f238";
}
.flaticon-381-star:before {
  content: "\f239";
}
.flaticon-381-star-1:before {
  content: "\f23a";
}
.flaticon-381-stop:before {
  content: "\f23b";
}
.flaticon-381-stop-1:before {
  content: "\f23c";
}
.flaticon-381-stopclock:before {
  content: "\f23d";
}
.flaticon-381-stopwatch:before {
  content: "\f23e";
}
.flaticon-381-stopwatch-1:before {
  content: "\f23f";
}
.flaticon-381-stopwatch-2:before {
  content: "\f240";
}
.flaticon-381-substract:before {
  content: "\f241";
}
.flaticon-381-substract-1:before {
  content: "\f242";
}
.flaticon-381-substract-2:before {
  content: "\f243";
}
.flaticon-381-success:before {
  content: "\f244";
}
.flaticon-381-success-1:before {
  content: "\f245";
}
.flaticon-381-success-2:before {
  content: "\f246";
}
.flaticon-381-sunglasses:before {
  content: "\f247";
}
.flaticon-381-switch:before {
  content: "\f248";
}
.flaticon-381-switch-1:before {
  content: "\f249";
}
.flaticon-381-switch-2:before {
  content: "\f24a";
}
.flaticon-381-switch-3:before {
  content: "\f24b";
}
.flaticon-381-switch-4:before {
  content: "\f24c";
}
.flaticon-381-switch-5:before {
  content: "\f24d";
}
.flaticon-381-sync:before {
  content: "\f24e";
}
.flaticon-381-tab:before {
  content: "\f24f";
}
.flaticon-381-target:before {
  content: "\f250";
}
.flaticon-381-television:before {
  content: "\f251";
}
.flaticon-381-time:before {
  content: "\f252";
}
.flaticon-381-transfer:before {
  content: "\f253";
}
.flaticon-381-trash:before {
  content: "\f254";
}
.flaticon-381-trash-1:before {
  content: "\f255";
}
.flaticon-381-trash-2:before {
  content: "\f256";
}
.flaticon-381-trash-3:before {
  content: "\f257";
}
.flaticon-381-turn-off:before {
  content: "\f258";
}
.flaticon-381-umbrella:before {
  content: "\f259";
}
.flaticon-381-unlocked:before {
  content: "\f25a";
}
.flaticon-381-unlocked-1:before {
  content: "\f25b";
}
.flaticon-381-unlocked-2:before {
  content: "\f25c";
}
.flaticon-381-unlocked-3:before {
  content: "\f25d";
}
.flaticon-381-unlocked-4:before {
  content: "\f25e";
}
.flaticon-381-upload:before {
  content: "\f25f";
}
.flaticon-381-upload-1:before {
  content: "\f260";
}
.flaticon-381-user:before {
  content: "\f261";
}
.flaticon-381-user-1:before {
  content: "\f262";
}
.flaticon-381-user-2:before {
  content: "\f263";
}
.flaticon-381-user-3:before {
  content: "\f264";
}
.flaticon-381-user-4:before {
  content: "\f265";
}
.flaticon-381-user-5:before {
  content: "\f266";
}
.flaticon-381-user-6:before {
  content: "\f267";
}
.flaticon-381-user-7:before {
  content: "\f268";
}
.flaticon-381-user-8:before {
  content: "\f269";
}
.flaticon-381-user-9:before {
  content: "\f26a";
}
.flaticon-381-video-camera:before {
  content: "\f26b";
}
.flaticon-381-video-clip:before {
  content: "\f26c";
}
.flaticon-381-video-player:before {
  content: "\f26d";
}
.flaticon-381-video-player-1:before {
  content: "\f26e";
}
.flaticon-381-view:before {
  content: "\f26f";
}
.flaticon-381-view-1:before {
  content: "\f270";
}
.flaticon-381-view-2:before {
  content: "\f271";
}
.flaticon-381-volume:before {
  content: "\f272";
}
.flaticon-381-warning:before {
  content: "\f273";
}
.flaticon-381-warning-1:before {
  content: "\f274";
}
.flaticon-381-wifi:before {
  content: "\f275";
}
.flaticon-381-wifi-1:before {
  content: "\f276";
}
.flaticon-381-wifi-2:before {
  content: "\f277";
}
.flaticon-381-windows:before {
  content: "\f278";
}
.flaticon-381-windows-1:before {
  content: "\f279";
}
.flaticon-381-zoom-in:before {
  content: "\f27a";
}
.flaticon-381-zoom-out:before {
  content: "\f27b";
}
