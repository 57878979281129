/*  .autocomplete{
    position: relative;
    display: inline-block;
  } */

/*   .resultadobusqueda {
      border: 1px solid #d4d4d4;
      border-radius: 5%;
      border-bottom: none;
      border-top: none;
      margin-top: 3% !important;
       z-index: 1051;
      left: 0;
      right: 10% !important;
      background-color: white;
      box-shadow: #d4d4d4;
    } */

.testBusqueda {
  position: absolute;
  background-color: white;
  margin-top: 5% !important;
  z-index: 10;
  border-radius: 5%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 10%;
  width: 450px !important;
  /*  width: auto; */
  /* min-width:450px; */
  top: 100%;
  left: 0px;
  /* height: 100%;
    max-height: 300px !important; */
}

/* prueba mobile*/
@media only screen and (max-width: 500px) {
  .testBusqueda {
    width: auto !important;
  }
}

/* Solo si la ventana tiene un ancho de oculto barra de busqueda*/
@media only screen and (max-width: 992px) {
  .testBusqueda {
    position: absolute;
    width: 100vw;
    height: 100vh;
    /* display: flex; */
    /* top: 0; */
    justify-content: center;
    align-items: center;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
  }
}

.alertBusqueda {
  position: absolute;
  background-color: white;
  z-index: 10;
  border-radius: 5%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 10%;
  top: 80%;
  left: 5%;
}

