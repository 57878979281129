/* CALENDAR */

/* centrar texto*/
.calendar_small .fc-scrollgrid-sync-table tbody tr td div div a {
  color: #000;
  margin: auto;
}

/* color de fondo dia actual*/
.calendar_small .fc .fc-daygrid-day.fc-day-today {
  background-color: rgba(213, 245, 227, 0.8);
  border-radius: 10px;
}

/*borde tabla*/
.calendar_small .fc .fc-scrollgrid {
  border-collapse: collapse;
}

.calendar_small .fc .fc-scrollgrid td {
  border-style: hidden !important;
}

.calendar_small .fc-col-header tbody th {
  border: 0px;
}

/*colo texto días*/
.calendar_small .fc-col-header tbody tr div a {
  color: #000;
}

/* oculta circulo del evento*/
.calendar_small .fc-direction-ltr .fc-daygrid-event .fc-event-time {
  display: none !important;
}

/*eventos en cinctulos*/
.calendar_small .fc-daygrid-event-harness a {
  border-radius: 100%;
  width: 15px;
  height: 15px;
  margin: auto !important;
}

/* titulo evento oculto*/
.calendar_small .fc-event-title-container {
  display: none !important;
}

/* tamaño titulo */
.calendar_small .fc .fc-toolbar-title {
  font-size: 1.2em;
}

/*ocultar fecha de evento*/
.fc-direction-ltr .fc-daygrid-event .fc-event-time {
  display: none;
}

/* alinear texto evento*/
.event .fc-event-title {
  text-align: initial;
}

/*color background fecha actual*/
.fc .fc-daygrid-day.fc-day-today {
  background-color: rgba(213, 245, 227, 0.8) !important;
}

/* cursos en calendario */
.calendar_small .fc-event {
  cursor: pointer;
}

.calendar_big .fc-event {
  cursor: default;
}

.calendar_small .fc .fc-scrollgrid {
  border-collapse: collapse;
  border-top: 0;
  border-left: 0;
}

.calendar_small .fc-theme-standard th {
  border: 0;
}

.calendar_small a {
  color: #8f07f9;
}
